<template>
<div class="gap">
  <!-- select elem for inject options (slot) from outer scope -->
  <select
  ref="select"
  class="select"
  :value="value"
  tabindex="-1"
  :multiple="multiple"
  :id="uuid"
  v-on="listeners"
  v-bind="attrs">
    <slot></slot>
  </select>
  <!-- multiple select -->
  <div
  v-if="multiple"
  class="selectbox multiple"
  :class="{disabled, readonly, multiple}">
    <ul
    ref="focused"
    tabindex="0"
    class="selected-items"
    @focusin="inputFocused = true"
    @focusout="inputFocused = false">
      <li
      v-for="item in selectedItems"
      class="item"
      :title="text(item)"
      @click.stop="toggleOptionSelection(option(item))"
      :key="item">
      {{text(item)}}
      </li>
      <input
      ref="input"
      v-show="isOptionsOpened || selectedItems.length == 0 || true"
      @focusin="inputFocused = true"
      @focusout="inputFocused = false"
      class="item input"
      type="text"
      :placeholder="(!selectedItems.length || isOptionsOpened) ? placeholder : ''"
      :value="searchText"
      @keydown.up.prevent="handleKeyNavigation($event, 'up')"
      @keydown.down.prevent="handleKeyNavigation($event, 'down')"
      @keydown.enter.prevent="handleEnterKey"
      @keydown.esc.prevent="closeDropdown"
      @keydown.backspace="handleBackspace"
      @input="inputIME" />
    </ul>
    <ul
    class="options custom-scrollbar"
    ref="options"
    tabindex="0"
    @focusin="optionHovered = true"
    @blur="optionHovered = false"
    @mouseleave="optionHovered = false">
      <li v-if="searchedOptions.length == 0" class="no-item">일치하는 항목이 없습니다</li>
      <li
      v-else
      v-for="(so, index) in searchedOptions"
      :key="so.value"
      v-html="so.text"
      class="option"
      :class="{
        selected: selectedItems.includes(so.value),
        disabled: so.disabled,
        'keyboard-selected': selectedIndex === index
      }"
      @click="toggleOptionSelection(so)" />
    </ul>
  </div>
  <!-- single select -->
  <div
  v-else
  class="selectbox single"
  :class="{disabled, readonly, multiple}">
    <ul
    ref="focused"
    tabindex="0"
    class="selected-items"
    @focusin="inputFocused = true"
    @focusout="inputFocused = false">
      <li
      v-if="selectedItem !== undefined"
      :title="text(selectedItem)"
      @click="openDropdownAndFocus"
      class="item">
        {{text(selectedItem)}}
      </li>
      <li
      v-else-if="selectedItem === undefined"
      :title="unbindLabel"
      @click="openDropdownAndFocus"
      class="item">
      {{unbindLabel}}
      </li>
      <input
      v-show="isOptionsOpened || selectedItem === undefined || true"
      @focusin="inputFocused = true"
      @focusout="inputFocused = false"
      ref="input"
      class="item input"
      type="text"
      :placeholder="(!selectedItem || isOptionsOpened) ? placeholder : ''"
      :value="searchText"
      @keydown.up.prevent="handleKeyNavigation($event, 'up')"
      @keydown.down.prevent="handleKeyNavigation($event, 'down')"
      @keydown.enter.prevent="handleEnterKey"
      @keydown.esc.prevent="closeDropdown"
      @input="inputIME" />
    </ul>
    <ul
    class="options custom-scrollbar"
    :class="{forceHoverOff}"
    ref="options"
    tabindex="0"
    @focusin="optionHovered = true"
    @blur="optionHovered = false"
    @mouseleave="optionHovered = false">
      <li
      tabindex="0"
      v-if="searchedOptions.length == 0"
      class="no-item">
        일치하는 항목이 없습니다
      </li>
      <li
      v-else
      v-for="(so, index) in searchedOptions"
      :key="so.value"
      v-html="so.text"
      class="option"
      :class="{
        selected: selectedItem == so.value,
        disabled: so.disabled,
        'keyboard-selected': selectedIndex === index
      }"
      @click="toggleOptionSelection(so)" />
    </ul>
  </div>
</div>
</template>

<script>
import escapeRegExp from 'lodash.escaperegexp'
export default {
  name: 'SpSelect2',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
    placeholder: { type: String, default: '검색어를 입력하세요' },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data () {
    return {
      refreshKey: 0,
      uuid: null,
      observer: null,
      elOptions: null, // options from slot (injected from mutation observer)
      searchText: '',
      inputFocused: false,
      optionHovered: false,
      isOptionsOpened: false,
      selectedIndex: -1, // 키보드 선택을 위한 인덱스 추가

      searchedOptions: [], // searched options by searchText
      selectedItems: [], // multiple selected values
      selectedItem: '', // single selected value
      unbindLabel: '',
      forceHoverOff: false,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {
    this.__watchOptions()
  },
  beforeDestroy () {
    // Clean up
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    focus () {
      this.$refs.focused.focus()

    },
    calculateOptionOpened () {
      this.debounce('calculateOptionOpened', () => {
        // inputFocused나 optionHovered가 true이면 드롭다운을 열기
        this.isOptionsOpened = this.inputFocused || this.optionHovered
      }, 10)
    },
    toggleOptionSelection (option) {
      let newv, oldv
      if (this.multiple) {
        oldv = JSON.stringify(this.selectedItems)
        const selectedOption = this.elOptions.find(o => o.value === option.value)
        selectedOption.selected = !selectedOption.selected
        for (const o of this.$refs.select.options) {
          if (o.value === option.value) {
            o.selected = !o.selected
            break
          }
        }
        this.selectedItems = this.elOptions.filter(o => o.selected).map(o => o.value)
        newv = JSON.stringify(this.selectedItems)
        this.$emit('input', this.selectedItems)
        // multiple 모드에서는 input에 focus 이동하고 검색어 초기화
        this.searchText = ''
        this.$nextTick(() => {
          // input 엘리먼트에 직접 포커스
          this.$refs.input.focus()
          // 방금 클릭한 항목의 인덱스 찾기
          const clickedIndex = this.searchedOptions.findIndex(o => o.value === option.value)
          if (clickedIndex >= 0) {
            this.selectedIndex = clickedIndex
          }
        })
      } else {
        oldv = this.selectedItem
        newv = option.value
        this.selectedItem = option.value
        for (const o of this.$refs.select.options) {
          o.selected = o.value === option.value
        }
        this.$emit('input', this.selectedItem)
        // 포커스 처리 변경
        this.searchText = '' // 검색어 초기화
        this.forceHoverOff = true
        setTimeout(() => {
          this.forceHoverOff = false
        }, 100)
        // blur 처리는 유지
        document.activeElement.blur()
      }
      // dispatch change event
      if (this.multiple) {
        if (JSON.stringify(newv) === JSON.stringify(oldv)) return
      } else {
        if (newv === oldv) return
      }
      const event = new Event('change', { bubbles: true })
      this.$refs.select.dispatchEvent(event)
      this.__applyOptions()
    },
    inputIME (e) {
      this.searchText = e.target.value
    },
    __watchOptions () {
      this.observer = new MutationObserver(
        this.__applyOptions,
      )
      // Setup the observer
      this.observer.observe(
        this.$refs.select,
        {
          childList: true,
          subtree: true, // 모든 하위 노드에서도 변경을 감지합니다.
          characterData: true, // 텍스트 노드의 변경을 감지합니다.
        },
      )
      this.__applyOptions()

    },
    __applyOptions () {
      const temp = Array.apply(null, this.$refs.select?.options)
      this.elOptions = temp.map(
        (option) => {
          return {
            value: option.value,
            text: option.text,
            selected: this.multiple
              ? this.selectedItems.includes(option.value) // multiple 모드일 때는 selectedItems 기준으로 판단
              : this.value == option.value, // single 모드일 때는 value 기준으로 판단
            disabled: option.disabled,
          }
        },
      )

      if (this.multiple) {
        // multiple 모드에서는 selectedItems가 이미 value watch에서 처리되므로 추가 처리 불필요
      } else {
        this.selectedItem = this.elOptions.find(o => o.selected)?.value
        if (this.selectedItem === undefined) {
          this.unbindLabel = this.elOptions[0]?.text
        }
      }

      // 검색어 처리를 searchText watch에서 통합 처리하도록 변경
      this.__updateSearchedOptions()
    },
    __updateSearchedOptions () {
      this.searchedOptions = this.searchText
        ? this.__searchAndHighlight(this.elOptions, this.searchText)
        : this.elOptions
    },
    __createFuzzyMatcher (input) {
      const pattern = input
        .split('')
        .map(this.__ch2pattern)
        .map((pattern) => '(' + pattern + ')')
        .join('.*?')
      return new RegExp(pattern)
    },
    __ch2pattern (ch) {
      const offset = 44032 /* '가'의 코드 */
      // 한국어 음절
      if (/[가-힣]/.test(ch)) {
        const chCode = ch.charCodeAt(0) - offset
        // 종성이 있으면 문자 그대로
        if (chCode % 28 > 0) {
          return ch
        }
        const begin = Math.floor(chCode / 28) * 28 + offset
        const end = begin + 27
        return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`
      }
      // 한글 자음
      if (/[ㄱ-ㅎ]/.test(ch)) {
        const con2syl = {
          ㄱ: '가'.charCodeAt(0),
          ㄲ: '까'.charCodeAt(0),
          ㄴ: '나'.charCodeAt(0),
          ㄷ: '다'.charCodeAt(0),
          ㄸ: '따'.charCodeAt(0),
          ㄹ: '라'.charCodeAt(0),
          ㅁ: '마'.charCodeAt(0),
          ㅂ: '바'.charCodeAt(0),
          ㅃ: '빠'.charCodeAt(0),
          ㅅ: '사'.charCodeAt(0),
        }
        const begin = con2syl[ch] || (ch.charCodeAt(0) - 12613) * 588 + con2syl['ㅅ']
        const end = begin + 587
        return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`
      }
      // 그 외엔 그대로
      // escapeRegExp는 lodash에서 훔쳐옴
      return escapeRegExp(ch)
    },
    handleEnterKey (event) {
      event.preventDefault() // form submit 방지
      if (this.isOptionsOpened && this.selectedIndex >= 0) {
        const selectedOption = this.searchedOptions[this.selectedIndex]
        // disabled된 옵션은 선택하지 않음
        if (!selectedOption.disabled) {
          this.toggleOptionSelection(selectedOption)
          // input 요소에 직접 포커스를 주도록 수정 (toggleOptionSelection에서 처리하므로 여기서는 제거)
        }
      } else {
        // 드롭다운이 닫혀있으면 열기
        this.isOptionsOpened = true
        // 열리면 input에 포커스
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    },
    handleKeyNavigation (event, direction) {
      if (!this.isOptionsOpened) {
        // 닫혀있을 때 아래 키를 누르면 열기
        if (direction === 'down') {
          this.isOptionsOpened = true
        }
        return
      }

      // disabled가 아닌 다음 옵션을 찾는 함수
      const findNextEnabledOption = (startIndex, direction) => {
        let currentIndex = startIndex
        let count = 0
        const maxCount = this.searchedOptions.length // 무한 루프 방지

        while (count < maxCount) {
          currentIndex = direction === 'up'
            ? (currentIndex <= 0 ? this.searchedOptions.length - 1 : currentIndex - 1)
            : (currentIndex >= this.searchedOptions.length - 1 ? 0 : currentIndex + 1)

          if (!this.searchedOptions[currentIndex].disabled) {
            return currentIndex
          }
          count++
        }
        return -1 // enabled된 옵션을 찾지 못한 경우
      }

      if (direction === 'up') {
        // 위 방향키: 이전 enabled 항목 선택
        this.selectedIndex = findNextEnabledOption(this.selectedIndex, 'up')
      } else {
        // 아래 방향키: 다음 enabled 항목 선택
        this.selectedIndex = findNextEnabledOption(this.selectedIndex, 'down')
      }

      // 선택된 옵션이 보이도록 스크롤 처리
      this.$nextTick(() => {
        const optionsContainer = this.$refs.options
        if (optionsContainer && this.selectedIndex >= 0) {
          const selectedOption = optionsContainer.children[this.selectedIndex]
          if (selectedOption) {
            selectedOption.scrollIntoView({ block: 'nearest' })
          }
        }
      })
    },
    closeDropdown () {
      // 모든 focus 제거
      if (this.$refs.input) {
        this.$refs.input.blur()
      }
      this.$refs.focused.blur()
      this.searchText = '' // 검색어 초기화
      this.isOptionsOpened = false
      this.selectedIndex = -1
      this.inputFocused = false // input focus 상태 초기화
      this.optionHovered = false // option hover 상태 초기화
    },
    __searchAndHighlight (options, searchText) {
      const regex = this.__createFuzzyMatcher(searchText)

      return options.filter(option => regex.test(option.text))
        .map(option => {
          let orderWeight = 0 // lower is better

          const replacer = (match, ...groups) => {
            const letters = groups.slice(0, searchText.length)
            let lastIndex = 0
            const highlighted = []

            for (let i = 0, l = letters.length; i < l; i++) {
              const idx = match.indexOf(letters[i], lastIndex)
              highlighted.push(match.substring(lastIndex, idx))
              highlighted.push(`<mark>${letters[i]}</mark>`)
              lastIndex = idx + 1
              orderWeight += (groups[groups.length - 1].indexOf(groups[groups.length - 3]))
              if (searchText[i] == letters[i]) {
                // 정확히 일치 시 -1
                orderWeight -= 1
              }
            }
            return highlighted.join('')
          }

          const text = option.text.replace(regex, replacer)
          return { ...option, text, orderWeight }
        })
        .sort((a, b) => a.orderWeight - b.orderWeight)
    },
    openDropdownAndFocus () {
      this.isOptionsOpened = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    handleSelectedItemClick (item) {
      // 선택된 아이템 클릭 시 드롭다운을 열고 input에 포커스
      this.isOptionsOpened = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    handleBackspace (event) {
      // input이 비어있고 멀티 모드이며 선택된 아이템이 있을 때만 동작
      if (this.multiple && this.searchText === '' && this.selectedItems.length > 0) {
        // 마지막 선택된 아이템 찾기
        const lastItem = this.selectedItems[this.selectedItems.length - 1]
        const lastOption = this.option(lastItem)

        // 마지막 아이템 제거 (toggleOptionSelection 활용)
        if (lastOption) {
          this.toggleOptionSelection(lastOption)
        }
      }
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
    text () {
      return (value) => {
        return this.elOptions?.find((i) => i.value === value)?.text // ?? value
      }
    },
    option () {
      return (value) => {
        return this.elOptions.find((i) => i.value === value)
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (newv, oldv) {
        if (this.multiple) {
          // multiple 모드일 때 단일 값을 배열로 변환
          const newValue = Array.isArray(newv) ? newv : (newv ? [newv] : [])
          if (JSON.stringify(newValue) === JSON.stringify(oldv)) return
          this.selectedItems = newValue
        } else {
          if (newv === oldv) return
          this.selectedItem = newv
        }
        this.__applyOptions()
      },
    },
    isOptionsOpened (newVal) {
      if (newVal) {
        // 드롭다운이 열릴 때 현재 선택된 값의 인덱스로 설정
        let currentIndex = -1
        if (this.multiple) {
          // 멀티 모드일 때는 선택된 항목 중 첫 번째 항목의 인덱스
          for (let i = 0; i < this.searchedOptions.length; i++) {
            if (this.selectedItems.includes(this.searchedOptions[i].value)) {
              currentIndex = i
              break
            }
          }
        } else {
          // 싱글 모드일 때는 선택된 항목의 인덱스
          currentIndex = this.searchedOptions.findIndex(option => option.value === this.selectedItem)
        }
        this.selectedIndex = currentIndex >= 0 ? currentIndex : -1

        // 드롭다운이 열릴 때 input에 포커스
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      } else {
        this.selectedIndex = -1
      }
    },
    inputFocused: {
      handler (newVal) {
        // input에 포커스가 있을 때 드롭다운 바로 열기
        if (newVal) {
          this.isOptionsOpened = true
        }
        this.calculateOptionOpened()
      },
    },
    optionHovered: {
      handler () {
        this.calculateOptionOpened()
      },
    },
    searchText: {
      handler () {
        this.$nextTick(() => {
          this.$refs.options.scrollTop = 0
        })
        this.__updateSearchedOptions()
      },
    },
  },
}
</script>
<style lang="scss">
td:has(.selectbox) {
  overflow: visible !important;
}
</style>
<style lang="scss" scoped>
ul, li, input {
  padding: 0;
  margin: 0;
  border: 0;
}

.gap {
  line-height: 16px;
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 60px !important;
  box-sizing: border-box;
  .select {
    pointer-events: none;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .selectbox {
    &.single .options .option.disabled {
      display: none;
    }
    position: relative;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    width: calc(100% - 5px);
    .selected-items {

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2.5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--theme-primary-color);
      }
      font-size: 14px;
      line-height: 16px;
      position: relative;
      margin:4px 0;
      border: 1px solid #ddd;
      border-radius: var(--theme-input-radius);
      box-sizing: border-box;
      padding: 3px 20px 3px 2px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 2px 5px;
      background: #fff;
      &:focus-within {
        border-color: var(--theme-primary-color);
        animation: shadow 0.1s ease-in-out forwards;
      }
      &:focus-within+.options, &+.options:hover
      {
        animation: shadow 0.1s ease-in-out forwards;
        border-color: var(--theme-primary-color);
        transform: scale(1);
        &.forceHoverOff {
          transform: scale(0);
        }
      }
      .item {
        height: 16px;
        line-height: 16px;
        cursor: pointer;
        padding: 1px 4px;
        border-radius: var(--theme-input-radius);
        font-weight: 500;
        word-break: break-all;
        list-style: none;
        color: #fff;
        background: var(--theme-primary-color);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .input {
        min-width: 50px;
        text-indent: 5px;
        padding :1px 0;
        height: 16px;
        line-height: 16px;
        color: black;
        background: #fff;
        min-width: 1px;
        flex-basis: 1px;
        flex-grow: 1;
        flex-shrink: 1;
        outline: none;
      }
    }
    .options {
      z-index: 3;
      position: absolute;
      transform: scale(0);
      overflow: auto;
      box-sizing: border-box;
      width: 100%;
      max-height: 200px;
      border: 1px solid #ddd;
      border-radius: var(--theme-input-radius);
      background: #ddd;
      display: flex;
      flex-direction: column;
      scroll-behavior: smooth;
      overscroll-behavior: contain;
      gap: 1px 0;
      .option {
        line-height: 1;
        text-indent: 12px;
        cursor: pointer;
        padding: 4px;
        font-weight: normal;
        word-break: break-all;
        list-style: none;
        color: #000;
        background: #fff;
        scroll-snap-align: start;
        &:hover {
          box-shadow: inset 0px -10px 20px #8a8a8a22;
        }
        &.selected {
          // font-weight: 600;
          font-weight: 600;
          text-indent: 0;
          background: var(--theme-primary-color);
          color: var(--theme-background-color);
          &:before {
            display: inline-block;
            width: 12px;
            font-weight: 600;
            content: '✓';
          }
        }
        &.disabled {
          color: var(--theme-light-color);
          pointer-events: none;
        }
        &.keyboard-selected {
          // background: var(--theme-primary-color);
          box-shadow: inset 0px -10px 20px #8a8a8a22;
          font-weight: 800;
          // color: var(--theme-background-color);
        }
      }
      .no-item {
        line-height: 16px;
        text-align: center;
        padding: 10px 4px;
        list-style: none;
        background: #fff;
        color: var(--theme-primary-color);
        word-break: keep-all;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
      .selected-items {
        // background: #eee;
        background: #F8F7EFaa;
        &:focus-within, &:focus {
          border: 1px solid #bbb;
          animation: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          width: 0;
          height: 0;
          margin-top: -2.5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #bbb;
        }
      }
      .item {
        background: #bbb !important;
      }
      .options {
        display: none;
        transform: scale(0) !important;
      }
    }
    &.readonly {
      pointer-events: none;
      opacity: 0.7;
      .selected-items {
        // background: #eee;
        background: #F8F7EFaa;
        &:focus-within, &:focus {
          border: 1px solid #bbb;
          animation: none;
        }
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          width: 0;
          height: 0;
          margin-top: -2.5px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #bbb;
        }
      }
      .input {display: none;}
      .options {
        display: none;
        transform: scale(0) !important;
      }
    }
  }
}
.custom-scrollbar {overflow-y: auto;}
/* 스크롤바 기본 스타일 */
.custom-scrollbar::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}
/* 스크롤바 트랙 스타일 */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #fff;
    border-left: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 0 var(--theme-input-radius) var(--theme-input-radius) 0;
}
/* 스크롤바 썸(이동하는 부분) 스타일 */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 9999px;
    border: 0px;
    box-shadow: none;
    border: 4px solid transparent;  /* 투명한 테두리 추가 */
    background-clip: padding-box;   /* 배경이 테두리 안쪽에만 적용되도록 설정 */
    width: 8px;                     /* 썸의 너비를 8px로 설정 */
  }
/* 스크롤바 호버 시 썸 스타일 */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--theme-primary-color);
}
</style>
