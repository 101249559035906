<template>
  <div class="datepicker-element-wrap">
    <el-date-picker
      v-model="dateValue"
      :type="type"
      :start-placeholder="this.dynamicStartDate"
      :end-placeholder="this.dynamicEndDate"
      :picker-options="pickerOptions"
      :format="customFormat"
      :time-format="customTimeFormat"
      :value-format="customValueFormat"
      :disabled="disabled"
      :disabled-date="disabledDate"
      :editable="!readonly"
      :placeholder="this.placeholder"
      range-separator=" ~ "
      clear-icon="el-icon-close"
      align="center"
    />
    <div class="filterDays" v-if="filterDays && dateValue">
      <sp-button
        v-for="(day, i) in filterDays"
        :key="day + i"
        class="gap"
        :class="{ primary: Array.isArray(day.day) && day.day.length > 0 && day.day.every(date => date === dateValue.find(d => d === date)) }"
        type="button"
        :value="day.name"
        @click.prevent="rangeSetDate(day.name)"
      >
        {{ day.name }}
      </sp-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SpElementpicker',
  props: {
    value: {
      type: [Number, String, Object, Array],
    },
    type: {
      type: String,
      default: 'daterange',
    },
    width: {
      type: String,
      default: '100%',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    valueToISOString: {
      type: [Boolean, String],
      default: false,
    },
    filterDays: {
      type: [Object, Array, Boolean],
      default: false,
    },
    pickerOptions: {
      type: [Object, Array],
    },
    readonly: {
      type: [Boolean, String],
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      customIcon: 'DatePickerIcon',
      filterList: '',
      filterValue: '',
      /*
      pickerOptions: {

        disabledDate  (time) {
          return time.getTime() > Date.now()
        },
      },
      */
    }
  },
  computed: {
    dynamicStartDate () {
      return this.startDate || this.setDate('start')
    },
    dynamicEndDate () {
      return this.endDate || this.setDate('end')
    },
    dateValue: {
      get () {
        this.value?.length || this.$emit('input', this.defaultDate)
        return this.value?.length === 0 ? this.defaultDate : this.value
      },
      set (value) {
        // console.log('set value', value)
        if (Array.isArray(value)) {
          const transformedValue = value.map((day, index) => this.SettoISOString(day, this.valueToISOString, index))
          this.$emit('input', transformedValue) // 변환된 배열을 emit
        } else {
          this.$emit('input', value)
        }
      },
    },
    defaultDate () {
      // 오늘 날짜
      const today = new Date()
      const lastMonth = new Date(today)
      lastMonth.setMonth(today.getMonth() - 1)
      // 날짜 형식 지정 (YYYY-MM-DD)
      const formatDate = date => {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const seconds = date.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      }
      // 결과 배열 반환
      if (this.type.includes('range')) {
        return [this.SettoISOString(formatDate(lastMonth), this.valueToISOString, 0), this.SettoISOString(formatDate(today), this.valueToISOString, 1)]
      } else {
        const timezoneOffset = new Date().getTimezoneOffset() * 60000
        const nowTime = new Date(Date.now() - timezoneOffset)
        return this.placeholder !== '' ? null : nowTime.toISOString().slice(0, 19).replace('T', ' ')
      }
    },
    customTimeFormat () {
      return 'HH:mm'
    },
    customFormat () {
      if (this.type.includes('datetime')) {
        return 'yyyy-MM-dd HH:mm'
      }
      if (this.type.includes('year')) {
        return 'yyyy'
      }
      if (this.type.includes('month')) {
        return 'yyyy-MM'
      }
      if (this.type.includes('date')) {
        return 'yyyy-MM-dd'
      }
      return 'yyyy-MM-dd HH:mm:ss'
    },
    customValueFormat () {
      if (this.type.includes('datetime')) {
        return 'yyyy-MM-dd HH:mm'
      }
      if (this.type.includes('year')) {
        return 'yyyy'
      }
      if (this.type.includes('month')) {
        return 'yyyy-MM'
      }
      if (this.type.includes('date')) {
        return 'yyyy-MM-dd'
      }
      return 'yyyy-MM-dd HH:mm:ss'
    },
  },
  methods: {
    disabledDate (time) {
      return time.getTime() > Date.now()
    },
    /** placeholder 시작일 */
    setDate (dateType) {
      const currentDate = new Date()
      const addDays = dateType === 'start' ? 7 : 0
      currentDate.setDate(currentDate.getDate() - addDays)
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')
      if (this.type === 'monthrange') {
        return `${year}-${month}`
      } else if (this.type === 'daterange') {
        return `${year}-${month}-${day}`
      } else {
        if (this.type.includes('datetime')) {
          const hours = currentDate.getHours().toString().padStart(2, '0')
          const minutes = currentDate.getMinutes().toString().padStart(2, '0')
          const seconds = currentDate.getSeconds().toString().padStart(2, '0')
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        } else {
          return `${year}-${month}-${day} 00:00:00`
        }
      }
    },
    setFilterDate (filterDays) {
      if (filterDays) {
        filterDays.forEach(filter => {
          const currentDate = new Date() // 각 반복에서 currentDate를 초기화
          const { name } = filter
          const unit = name.includes('개월') ? 'Month' : (name.includes('일') ? 'Date' : 'Year')
          const value = parseInt(name) || 1 // 숫자로 파싱하고, 숫자가 아니면 기본값 1
          currentDate[`set${unit}`](currentDate[`get${unit}`]() - value)
          filter.day = [
            this.SettoISOString(moment(currentDate).format('YYYY-MM-DD'), this.valueToISOString, 0),
            this.SettoISOString(moment().format('YYYY-MM-DD'), this.valueToISOString, 1),
          ]
        })
      }
    },
    rangeSetDate (dataType) {
      const currentDate = new Date()
      let setDate

      const dateMapping = {
        일: 'Date',
        월: 'Month',
        년: 'FullYear',
      }

      const dayText = dataType.replace(/[^0-9]/g, '') // '일' 이전의 숫자를 추출
      const setday = parseInt(dayText, 10) // 추출한 숫자를 정수로 변환

      for (const [unit, method] of Object.entries(dateMapping)) {
        if (dataType.includes(unit)) {
          setDate = new Date(currentDate[`set${method}`](currentDate[`get${method}`]() - setday))
          break
        }
      }

      const dates = []
      dates.push(this.SettoISOString(moment(setDate).format('YYYY-MM-DD'), this.valueToISOString, 0))
      dates.push(this.SettoISOString(moment().format('YYYY-MM-DD'), this.valueToISOString, 1))
      this.$emit('input', dates)
    },
    SettoISOString (dateTime, includeTime = true, index = 0) {
      // dateTime이 문자열인 경우 Date 객체로 변환
      const dateObject = typeof dateTime === 'string' ? new Date(dateTime) : dateTime
      if (includeTime) {
        let hours, minutes, seconds, milliseconds

        if (this.type.includes('datetime')) {
          // datetime 타입일 때는 시간만 설정하고 초는 항상 00으로 고정
          if (index === 0) {
            // 시작 시간
            hours = dateObject.getHours().toString().padStart(2, '0')
            minutes = dateObject.getMinutes().toString().padStart(2, '0')
            seconds = '00'
            milliseconds = '000'
          } else {
            // 종료 시간
            hours = dateObject.getHours().toString().padStart(2, '0')
            minutes = dateObject.getMinutes().toString().padStart(2, '0')
            seconds = '00'
            milliseconds = '000'
          }
        } else {
          // 기존 로직 유지
          if (index === 0) {
            hours = '00'
            minutes = '00'
            seconds = '00'
            milliseconds = '000'
          } else {
            hours = '23'
            minutes = '59'
            seconds = '59'
            milliseconds = '999'
          }
        }
        return `${dateObject.toISOString().slice(0, 10)}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
      } else {
        return `${dateObject.toISOString().slice(0, 10)}`
      }
    },
  },
  mounted () {
    this.setFilterDate(this.filterDays)
  },
}
</script>
<style lang="scss" scoped>
.datepicker-element-wrap {
  display: flex;
  align-items: center;
  gap: 4px;
  &.isError {
    .el-range-editor {
      border-color: #e94d4d;
      &.is-active {
        border-color: #e94d4d;
        &:hover {
          border-color: #e94d4d;
        }
      }
    }
  }
}
/deep/ .el-range-editor {
  &.is-active {
    border-color: #ddd;
    border-color: var(--theme-primary-color);
    animation: shadow 0.1s ease-in-out forwards;
    &:hover {
    color:#fff;
      border-color: #ddd;
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
  }
  &.is-disabled {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    &.is-active {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
    .el-range-separator {
      color: #cccccc;
    }
    input {
      background-color: #f2f2f2;
    }
    &:hover {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
    &:focus {
      background-color: #f2f2f2;
      border-color: #f2f2f2;
    }
  }
  .el-range__icon,
  .el-range-separator,
  .el-range__close-icon {
    line-height:1;
  }
  .el-range__close-icon {
    display:none!important;
  }
  .el-range-separator {
    padding: 0;
  }
  .el-range-input {
    font-weight: 500;
    line-height: 140%;
    width: 50%;
    font-size: 15px;
  }
}
.el-date-editor--daterange.el-input__inner,
.el-date-editor--monthrange.el-input__inner {
  width: auto;
  max-width: 280px;
}
/deep/ .el-date-editor {
  .el-range__icon {
    color:#3d5afe;
  }
}
/deep/ .el-date-editor {
  &.el-input {
    width:160px;
  }
  .el-input__suffix {
    display:none!important;
  }
  .el-input__inner {
    padding: 4px 0 4px 30px;
    font-size:14px;
    line-height:16px;
  }
}
/deep/ .el-input__inner {
  height: auto;
  margin:4px 0;
  padding:4px 10px;
  border-radius:5px;
  border:1px solid #ddd;
}
.filterDays {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0;
  /deep/ .gap {
    line-height:1;
  }
}
</style>
<style lang="scss">
.el-date-table td {
    display: table-cell!important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #3d5afe !important;
}
.el-month-table td.today .cell {
  color: #3d5afe !important;
}
.el-month-table td .cell:hover {
  color: #3d5afe !important;
  font-weight:700;
}
.el-date-table td.today span {
  color: #3d5afe !important;
}
.el-date-table td.current:not(.disabled) span {
  color: #FFF!important;
  background-color: #3d5afe!important;
}
.el-button--text {
  color: #3d5afe!important;
}
.el-button--text:focus, .el-button--text:hover {
  color: #3d5afe!important;
}
.el-time-panel__btn.confirm {
  color: #3d5afe!important;
}
.el-button.is-plain:focus, .el-button.is-plain:hover {
  color: #3d5afe!important;
  border-color: #3d5afe!important;
}
.el-month-table td.today.end-date .cell,
.el-month-table td.today.start-date .cell {
  color: #fff !important;
}
.el-month-table td.in-range.end-date .cell:hover,
.el-month-table td.in-range.start-date .cell:hover {
  color:#fff!important;
}
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #edf0ff !important;
}
.el-month-table td.end-date .cell,
.el-month-table td.start-date .cell {
  background-color: #3d5afe !important;
}
.el-month-table td.current:not(.disabled) .cell {
  color: #3D5AFE!important;
}
.el-date-table td.today.end-date span,
.el-date-table td.today.start-date span {
  color: #fff !important;
}
.el-date-table td.available:hover {
  color: #3D5AFE;
}
.el-input {
  width: 100%;
}
.el-input__icon {
  line-height: 2.5;
  color: #3d5afe;
}
</style>
