const getDefaultState = () => {
  return {
    inquirys: [],
    inquiry: {},
    originFilter: {},
    totalCount: 0,
    contactStepCodes: [],
    bdInquiryTypeCodes: [],
    admins: [],
    inquiryStatusCodes: [],
    contactFailureReasonCodes: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalCount: (state) => state.totalCount,
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.totalCount || 0
      return Math.ceil(count / limit)
    },
    originFilter: (state) => state.originFilter,
    inquiry: (state) => state.inquiry,
    contactStepCodes: (state) => state.contactStepCodes,
    bdInquiryTypeCodes: (state) => state.bdInquiryTypeCodes,
    admins: (state) => state.admins,
    inquiryStatusCodes: (state) => state.inquiryStatusCodes,
    contactFailureReasonCodes: (state) => state.contactFailureReasonCodes,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setInquirys (state, data) {
      state.inquirys = data.inquirys
      state.totalCount = data.totalCount
    },
    setInquiry (state, data) {
      state.inquiry = data
    },
    setAdmins (state, data) {
      state.admins = data.crewList
    },
    setContactStepCodes (state, data) {
      state.contactStepCodes = data.contactStepCodes
    },
    setBdInquiryTypeCodes (state, data) {
      state.bdInquiryTypeCodes = data.bdInquiryTypeCodes
    },
    setContactFailureReasonCodes (state, data) {
      state.contactFailureReasonCodes = data.contactFailureReasonCodes
    },
    setInquiryCodes (state, data) {
      state.inquiryStatusCodes = data.inquiryStatusCodes
    },
    setInquiryUseFilters (state, data) {
      state.contactStepCodes = data.contactStepCodes
      state.bdInquiryTypeCodes = data.bdInquiryTypeCodes
      state.contactFailureReasonCodes = data.contactFailureReasonCodes
      state.inquiryStatusCodes = data.inquiryStatusCodes
    },
  },
  actions: {
    async getInquirys ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys`, payload)
        commit('setInquirys', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postInquiry ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInquiry ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putInquiry ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAdmins ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}crew`, {
          limit: 1000,
          offset: 0,
          status: 'available',
        })
        commit('setAdmins', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInquiryCommonCodes ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}inquiry-common-codes`, payload)
        commit('setInquiryUseFilters', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postInquiryContactContents ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}/contact-contents`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteInquiryContactContents ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}/contact-contents/${payload.inquiryContentsId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putInquiryContactContents ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}/contact-contents/${payload.inquiryContentsId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postInquirys ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInquirysExcelDownload ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}inquirys-excel`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteTourSchedule ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.SPARK_ONE_CREW_API_URL}inquirys/${payload.bdInquiryId}/tour-schedules/${payload.tourScheduleId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
