export default [
  {
    path: '/inquirys',
    name: 'InquiryList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/inquiry/Inquirys'),
  },
  {
    path: '/inquirys/:bdInquiryId',
    name: 'InquiryDetail',
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '인쿼리 문의 내역 상세',
    },
    component: () => import('@/components/inquiry/InquiryDetail'),
  },
]
