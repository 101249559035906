export default [
  {
    path: '/in-vocs',
    name: 'InVocListPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/invoc/InVocListPage.vue'),
  },
  {
    path: '/in-vocs/:id/edit',
    name: 'InVocEditPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '문의 관리 상세',
    },
    component: () => import('@/components/invoc/InVocEditPage.vue'),
  },
]
