export default class ObjectUtility {
  static filterEmptyString (obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        const filteredArray = value.filter(v => v !== '')
        if (filteredArray.length > 0) {
          acc[key] = filteredArray
        }
      } else if (value !== '') {
        acc[key] = value
      }
      return acc
    }, {})
  }
}
