import ObjectUtility from '@/util/ObjectUtility'

const getDefaultState = () => {
  return {
    originFilter: {},
    vocStatuses: [],
    vocTypes: [],
    branches: [],
    admins: [],
    list: [],
    count: 0,
    detail: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setGetListMetaResponse (state, data) {
      state.vocStatuses = data.vocStatuses
      state.branches = data.branches
    },
    setGetListResponse (state, data) {
      state.list = data.inVocs
      state.count = data.totalCount
    },
    setGetDetailResponse (state, data) {
      const { branches, vocTypes, admins, vocStatuses, ...detail } = data

      state.branches = branches
      state.vocTypes = vocTypes
      state.admins = admins
      state.vocStatuses = vocStatuses

      detail.admin = state.admins.find(v => v.id === detail.adminId) ?? null
      delete detail.adminId
      state.detail = detail
    },
  },
  actions: {
    async update (context, payload) {
      let response = null
      try {
        const { vocId, ...body } = payload
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}in-vocs/${vocId}`, body)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getListMeta ({ commit }) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}in-vocs/meta`)
        commit('setGetListMetaResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}in-vocs`, ObjectUtility.filterEmptyString(payload))
        commit('setGetListResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}in-vocs/${payload.vocId}`)
        commit('setGetDetailResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
